import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"

// Hooks
import { useDragAndScroll } from "../utils/hooks"

// HOCs
import { withPage } from "../hocs/"

// Components
import { Col, Row } from "./layout/Grid"
// import TestimonialCard from "./TestimonialCard"
import AnimatedTextScrollWrapper from "./animation/AnimatedTextScrollWrapper"
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"
import BackgroundArrowsComponent from "./icons/background-arrows"

// Styles
import textStyles from "../styles/textStyles"
import colors from "../styles/colors"
import { mq } from "../styles/utils/mq"

// Types
import { IPageProvider } from "../types/"
import { ITestimonials } from "../types/prismic/Homepage/Testimonials"

interface IProps extends ITestimonials {
  page: IPageProvider
  showBackgroundArrows?: boolean
}

// Config
// Themes alter the appearance of the testimonials based on the colour of the page
const THEME_LIGHT = "light"
const THEME_DARK = "dark"

// Utils
const getTheme = (color: string) => {
  switch (color) {
    case colors.black:
      return THEME_LIGHT
    default:
      return THEME_DARK
  }
}

const Testimonials = ({
  page,
  title,
  text,
  items,
  showBackgroundArrows,
}: IProps) => {
  const [listenersExist, setListenersExist] = useState<boolean>(false)
  const [theme, setTheme] = useState<"light" | "dark">(THEME_DARK)
  const [scrollable, setScrollable] = useState<boolean>(false)

  const testimonials = useRef<HTMLDivElement>(null)

  const [isClickable, cleanUp] = useDragAndScroll(
    testimonials,
    listenersExist,
    setListenersExist
  )

  useEffect(() => {
    setTheme(getTheme(page.color))
    return cleanUp()
  }, [page.color])

  const handleScrollable = () => {
    const windowWidth = window.innerWidth
    const elementWidth =
      testimonials.current && testimonials.current.scrollWidth
    if (elementWidth && elementWidth > windowWidth) {
      setScrollable(true)
    } else {
      setScrollable(false)
    }
  }

  useEffect(() => {
    handleScrollable()
    window.addEventListener("resize", handleScrollable)

    return () => {
      window.removeEventListener("resize", handleScrollable)
    }
  }, [])

  return (
    <Container>
      {showBackgroundArrows && (
        <BackgroundWrapper>
          <Background>
            <BackgroundArrows />
          </Background>
        </BackgroundWrapper>
      )}
      <TextRow theme={theme}>
        <Col xxs={8} m={14}>
          <TitleWrapper>
            <AnimatedTextScrollWrapper
              text={[title]}
              textStyles={textStyles.headingM}
            />
          </TitleWrapper>
        </Col>
        {text && text.html && (
          <Col>
            <AnimatedScrollWrapper>
              <Images>
                {items.map(item => (
                  <ImageContainer>
                    {item.logo && (
                      <Logo src={item.logo.url} alt={item.logo.alt} />
                    )}
                    <p>
                      {item.quote}”<p>- {item.name}</p>
                    </p>
                  </ImageContainer>
                ))}
              </Images>
            </AnimatedScrollWrapper>
          </Col>
        )}
      </TextRow>

      <AnimatedScrollWrapper>
        <TestimonialsRow
          ref={testimonials}
          grabbing={!isClickable}
          scrollable={scrollable}
        >
          {items.map((testimonial, i) => {
            const key: string = `testimonial-card-${testimonial.name}-${i}`
            return <TestimonialWrapper key={key}></TestimonialWrapper>
          })}
        </TestimonialsRow>
      </AnimatedScrollWrapper>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const BackgroundWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  transform: translateY(-50%);
  z-index: -1;
`

const Background = styled.div`
  position: relative;
  height: 100vw;
  height: 100vw;
  overflow: hidden;
`

const BackgroundArrows = styled(BackgroundArrowsComponent)`
  width: 156vw;
  height: 100vw;
  transform: translateX(-25%);

  ${mq.from.M`
    width: 106.25vw;
    transform: none;
  `}

  ${mq.from.L`
    width: 105vw;
  `}

  ${mq.from.XXL`
    width: 107.7vw;
    transform: translateX(-3%);;
  `}
`

const ImageContainer = styled.div`
  width: 100%;

  > p {
    margin-top: 8px;
    position: relative;
  }

  > p:before {
    content: "“";
    position: absolute;
    left: -16px;
    top: 0;
  }

  ${mq.from.M`
    width: 33.3333%;
    &:nth-child(2) {
      margin-left: 33.3333%;
    }
    &:nth-child(3) {
      margin-left: 66.6666%;
    }
  `}
`

const TextRow = styled(Row)`
  color: ${({ theme }) => (theme === "dark" ? colors.black : colors.white)};
  width: 100%;
  padding: 0px 8px;

  ${mq.from.L`
    padding: 0px 16px;
  `}
`

const TitleWrapper = styled.div`
  margin-top: 10px;
`

const Logo = styled.img`
  width: 90px;
  display: block;
  ${mq.from.M`
  width: 33.33%`}
`

const Images = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 1436px;
  padding-left: 19px;
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 64px;

  ${mq.from.M`
    margin-top: 73px;
    margin-bottom: 21px;
    gap: 37px;
  `}

  ${mq.from.XL`
    margin-top: 162px;
    margin-bottom: 153px;
    gap: 0;
    p {
      margin-bottom: 0;
    }
  `}
`

const TestimonialWrapper = styled.div`
  flex: 0 0 240px;
`

const TestimonialsRow = styled(Row)<{ grabbing: boolean; scrollable: boolean }>`
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  ${({ grabbing, scrollable }) =>
    scrollable && `cursor: ${grabbing ? "grabbing" : "grab"}`};

  /* hide horizontal scrollbar */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`

export default withPage(Testimonials)
