import React from "react"
import styled from "styled-components"

// Styling
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Components
import { Row, Col } from "../components/layout/Grid"
import AnimatedScrollWrapper from "../components/animation/AnimatedScrollWrapper"
import AnimatedTextScrollWrapper from "../components/animation/AnimatedTextScrollWrapper"
import Link from "./generic/Link"

// Types
import { IHowWeWork as IProps } from "../types/prismic/Homepage"

const HowWeWork = ({ title, text, media, link, items }: IProps) => (
  <Container>
    <AnimatedScrollWrapper>
      <TextRow>
        <Col xxs={8} m={14}>
          {title && (
            <TitleWrapper>
              <AnimatedTextScrollWrapper
                text={[title]}
                textStyles={textStyles.headingM}
              />
            </TitleWrapper>
          )}
        </Col>
        {text && text.text && (
          <Col xxs={8} m={8}>
            <AnimatedScrollWrapper>
              <Text
                dangerouslySetInnerHTML={{
                  __html: text.html,
                }}
              />
            </AnimatedScrollWrapper>
          </Col>
        )}
      </TextRow>
      <Row>
        {media && (
          <MediaWrapper l={11} lOffset={4}>
            <Video src={media} autoPlay loop muted playsInline controls>
              <p>
                If you are reading this, it is because your browser does not
                support the HTML5 video element.
              </p>
            </Video>
          </MediaWrapper>
        )}
        {items && items.length > 0 && (
          <ItemsContainer>
            {items.map((item, index) => (
              <Item key={`${item.title}-${index}`}>
                <HorizontalLine />
                <ItemText>{item.title}</ItemText>
                <ItemText>{`— ${item.description}`}</ItemText>
                {item.image && (
                  <Image src={item.image.url} alt={item.image.alt} />
                )}
              </Item>
            ))}
          </ItemsContainer>
        )}
      </Row>
      <Background>
        <Row>
          <Col xxs={6} xxsOffset={1} m={11} mOffset={4} l={8} lOffset={7}>
            <Link url={link.url} title={link.label} color={colors.black} />
          </Col>
        </Row>
      </Background>
    </AnimatedScrollWrapper>
  </Container>
)

const Container = styled.div`
  color: ${colors.white};
  width: 100%;

  ${mq.from.M`
    position: relative;
  `}

  ${mq.from.XXL`
    /* duplicate style because of IE */
    padding-top: 3.5vw;
    padding-top: calc(var(--base-column-size) * 0.56);
  `}
`

const HorizontalLine = styled.div`
  display: inline-block;
  height: 2px;
  width: 100%;
  margin-left: 0px;
  margin-top: 16px;
  margin-right: 16px;
  background: ${colors.black};
`
const TextRow = styled(Row)`
  width: 100%;
  background-color: white;
  padding: 0px 8px;
  position: relative;

  ${mq.from.L`
  padding: 0px 16px;
`}
`
const Background = styled.div`
  background: ${colors.white};
`

const ItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${colors.white};
  overflow-y: scroll;
  width: 100%;
`

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  margin-top: 48px;

  ${mq.from.M`
    margin-top: 72px;
  `}

  ${mq.from.L`
    height: 100%;
    margin-top: 112px;
  `}
`

const ItemText = styled.p`
  margin: 0;
  color: ${colors.black};
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 8px 8px 8px;
  min-width: calc(60% - 16px);
  background-color: white;

  ${mq.from.M`
    padding: 94px 16px 8px 16px;
    min-width: calc(25% - 8px);
  `}

  ${mq.from.L`
    padding: 89px 16px 8px 16px;
    min-width: calc(25% - 8px);
  `}
`

const TitleWrapper = styled.div`
  color: ${colors.black};
  font-size: 16px;
`

export const Text = styled.div`
  p {
    /* duplicate style because of IE */
    margin-bottom: 2.5vw;
    margin-bottom: calc(var(--base-column-size) / 5);

    &:last-child {
      /* duplicate style because of IE */
      margin-bottom: 1.5vw;
      margin-bottom: calc(var(--base-column-size) / 4);
    }
  }

  ${mq.from.M`
    /* to include last child's bottom margin to the parent */
    overflow: auto;

    p {
      /* duplicate style because of IE */
      margin-bottom: 2vw;
      margin-bottom: calc(var(--base-column-size) / 3);
    }
  `}

  ${mq.from.L`
    p:last-child {
      /* duplicate style because of IE */
      margin-bottom: 3vw;
      margin-bottom: calc(var(--base-column-size) / 2);
    }
  `}

  ${mq.from.XL`
    p {
      /* duplicate style because of IE */
      margin-bottom: 1.5vw;
      margin-bottom: calc(var(--base-column-size) / 4);
    }
  `}
`

const MediaWrapper = styled(Col)`
  margin-bottom: 32px;

  ${mq.from.L`
    margin-bottom: 40px;
  `}
`

export const Video = styled.video`
  display: block;
  height: auto;
  width: 100%;
`

export default HowWeWork
