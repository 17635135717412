import styled from "styled-components"
import { Link as LinkComponent } from "gatsby"

// Styling
import { mq } from "../utils/mq"
import { fontWeights } from "../fonts"
import colors from "../colors"
import textStyles from "../textStyles"

// Main styles
export const Wrapper = styled.ul`
  list-style: none;
  margin: 0;
  color: ${colors.white};
`

export const Article = styled.li`
  min-height: var(--base-column-size);
  margin-bottom: var(--base-column-size);

  ${mq.from.M`
  `}
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 100px;

  ${mq.from.M`
  flex-direction: row;
  `}
`
export const Metadata = styled.div`
  /* duplicate style because of IE */
  margin-bottom:0
  margin-bottom: calc(var(--base-column-size) / 2);

  ${mq.from.M`
    flex: none;
    margin-right: 8vh;
    margin-right: var(--base-column-size);
  `}

  p {
  ${textStyles.hyperlink};
  ${textStyles.headingS};
  align-self: flex-start;
  color: ${colors.white};
  font-weight: ${fontWeights.medium};
  text-align: initial;
  }
`

export const ArticleLink = styled(LinkComponent)`
  ${textStyles.hyperlink};
  ${textStyles.headingS};
  align-self: flex-start;
  color: ${colors.white};
  font-weight: ${fontWeights.medium};
  text-align: initial;

  &:hover {
    color: ${colors.accent1};
    text-decoration: none;
  }
`
