import React from "react"

// Types
import { IBlogPost } from "../types/components/Blog"

import styled from "styled-components"
// Styling
import colors from "../styles/colors"
import {
  Wrapper,
  Article,
  Metadata,
  ArticleLink,
} from "../styles/components/ArticleList"

// Components
import Divider from "./layout/Divider"
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"
import { mq } from "../styles/utils/mq"
import { Col } from "./layout/Grid"
import { Link as LinkComponent } from "gatsby"

interface IProps {
  currentPage: string
  posts: IBlogPost[]
}

const BlogPostList = ({ currentPage, posts }: IProps) => (
  <Wrapper>
    {posts.map((post: IBlogPost, index: number) => (
      <Article key={index}>
        <AnimatedScrollWrapper>
          <Divider color={colors.white} />
        </AnimatedScrollWrapper>

        <AnimatedScrollWrapper>
          <ContentWrapper>
            <Content>
              <TextContainer>
                <Col l={4} lOffset={0} m={4} mOffset={0}>
                  <Metadata>
                    <PostTypeTitle>{post.type && `${post.type}`}</PostTypeTitle>
                  </Metadata>
                </Col>
                <Col l={8} lOffset={2} m={8} mOffset={2}>
                  {post.slug && post.title && (
                    <ArticleLink
                      to={`/${
                        currentPage === "whitepaper" ? "whitepaper" : "blog"
                      }/${post.slug}`}
                    >
                      {post.title}
                    </ArticleLink>
                  )}
                </Col>
              </TextContainer>

              <ArrowCol l={4} lOffset={0} m={4} mOffset={0}>
                <ArrowLink
                  to={`/${
                    currentPage === "whitepaper" ? "whitepaper" : "blog"
                  }/${post.slug}`}
                >
                  <ArrowWrapper>
                    <Arrow color={``}></Arrow>
                  </ArrowWrapper>
                </ArrowLink>
              </ArrowCol>
            </Content>
            {post.image.url && (
              <BlogPostImgLink
                to={`/${currentPage === "whitepaper" ? "whitepaper" : "blog"}/${
                  post.slug
                }`}
              >
                <BlogPostImg src={post.image.url} alt={post.image.alt} />
              </BlogPostImgLink>
            )}
          </ContentWrapper>
        </AnimatedScrollWrapper>
      </Article>
    ))}
  </Wrapper>
)

const Content = styled.div`
  width: 100%;
  display: flex;

  ${mq.from.M`
  flex-direction: row;
  `}
`
const ContentWrapper = styled.div`
  margin-bottom: 100px;
`
const BlogPostImgLink = styled(LinkComponent)`
  margin-top: 10px;
  ${mq.from.M`
  display: block;
  max-width: 45%
  margin-left: auto;
  margin-top: 30px;
`}
  ${mq.from.L`
  margin-top: 0px;`}
`
const BlogPostImg = styled.img`
  width: 100%;
`

const ArrowWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 30px;
  overflow: hidden;
`
const ArrowLink = styled(LinkComponent)`
  display: block;
  height: 100%;
`
const TextContainer = styled.div`
  ${mq.from.M`
    width: 120%;
    display: flex;
  `}
`
const ArrowCol = styled(Col)`
  &:hover {
    div > a {
      color: ${colors.accent1};
      background-color: ${colors.accent1};
      text-decoration: none;
      &::before,
      &::after {
        color: ${colors.accent1};
        background-color: ${colors.accent1};
      }
    }
  }
`
const PostTypeTitle = styled.p`
  &:hover {
    text-decoration: none !important;
  }
`

const Arrow = styled.a`
  display: block;
  position: absolute;
  height: 2px;
  background-color: white;
  transform: translateX(-43px);
  display: flex;
  align-self: center;
  margin-right: -38px;
  
  :before,
  :after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    width: 16px;
    background-color:white;
    transform-origin: center right;
  }

  :before {
    transform: rotate(-45deg) translateX(1px);
  }

  :after {
    transform: rotate(45deg) translateX(1px);
  }

  ${mq.from.XXS`
   width: 70px; 
  `}

  ${mq.from.M`
    width: 85px;
  `}

  ${mq.from.L`
    width: 226px;
    margin-top:15px;
  `}

  ${mq.from.XL`
    margin-top:46px;
  `}

  ${mq.from.XL`
  :before,
  :after {
    /* same with the link font size */
    width: 18px;
    width: calc(18px + (30 - 18) * ((100vw - 1280px) / (1920 - 1280)));
  }
`}

${mq.from.XXL`
  height: 3px;

  :before,
  :after {
    height: 3px;
    /* same with the link font size */
    width: 30px;
    width: calc(var(--base-fluid-font-size) * 1.875);
  }
`}
`
export default BlogPostList
