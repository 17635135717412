import React from "react"
import styled, { css } from "styled-components"

// HOCs
import { withPage } from "../hocs"

// Types
import { ITryUs } from "../types"
import { IBlockTheme } from "../types/prismic/ContactBlocks"
import { IPageProvider } from "../types/providers/PageProvider"

// Styling
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Components
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"
import AnimatedTextScrollWrapper from "./animation/AnimatedTextScrollWrapper"
import LinkComponent from "./generic/Link"
import { Col } from "./layout/Grid"

// Utils
const getBlockTheme = (theme: IBlockTheme) => {
  switch (theme) {
    case "light":
      return themeLightBlock
    case "dark":
      return themeDarkBlock
    default:
      return themeAccentBlock
  }
}

interface IProps extends ITryUs {
  className: string
  page: IPageProvider
  transparent?: boolean
}

const CrossLink = React.memo(
  ({ className, page, title, linkLabel, transparent, blockTheme = "accent" }: IProps) => (
    <AnimatedScrollWrapper className={className}>
      <Wrapper>
        <Block theme={blockTheme} transparent={transparent} >
          {title && (
            <TitleWrapper theme={page.color} m={8}>
              <AnimatedTextScrollWrapper
                text={[title]}
                textStyles={textStyles.headingM}
              />
            </TitleWrapper>
          )}

          {linkLabel && (
            <Col xxs={6} xxsOffset={1} m={11} mOffset={4} l={8} lOffset={7}>
              <Link
                url="/contact-us"
                title={linkLabel}
                theme={blockTheme as IBlockTheme}
              />
            </Col>
          )}
        </Block>
      </Wrapper>
    </AnimatedScrollWrapper>
  )
)

// Theme styles
const themeLightBlock = css`
  background-color: ${colors.white};
  color: ${colors.black};
`

const themeDarkBlock = css`
  background-color: ${colors.black};
  color: ${colors.white};
`

const themeAccentBlock = css`
  background-color: white;
  color: ${colors.black};
`

// Main styles
const Wrapper = styled.div``

const Block = styled(Col) <{ theme?: IBlockTheme, transparent?: boolean }>`
  padding-bottom: 32px;
  ${({ theme }) => (theme ? getBlockTheme(theme) : themeDarkBlock)};
  ${({ transparent }) => transparent ? css`background-color: transparent !important;` : ''}

  ${mq.from.L`
    padding-bottom: 40px;
  `}

  ${mq.from.XL`
    padding-bottom: 64px;
  `}
`

const TitleWrapper = styled(Col) <{ theme: string }>`
  padding: 0px 8px;
  color: ${colors.black};

  ${mq.from.M`
    /* duplicate style because of IE */
    margin-bottom: 4.16vw;
    margin-bottom: calc(var(--base-column-size) * 2 / 3);
  `}

  ${mq.from.L`
    padding: 0px 16px;

  `}
`

const Link = styled(LinkComponent)`
  > span {
    /* duplicate style because of IE */

    ${mq.from.S`
      flex-basis: inherit;
    `}
  }
`

export default withPage(CrossLink)
