import { useState, useEffect, useCallback, TouchEvent } from "react"

export type DirectionType = "up" | "down"

// Setup
// The min "scroll" amount to register a new direction
const THRESHOLD_DELTA_Y = 20

// The min number of consecutive up/down movements
// to register a stable overall "scrolling" direction
const THRESHOLD_DIRECTION = 8

// Init non-state defaults
let directionCounter = 0
let deltaPeak = 0

/**
 * NOTES:
 * On some devices, such as Mac trackpads or infinite scrolling mice,
 * events will keep firing even after releasing device
 * due to predictive scrolling which creates additional trailing events.
 *
 * To prevent this, a few mechanisms can be set in place:
 * 1. Eliminate all jittery movements by checking that a number of
 * x events (directionCounter) all have the same direction.
 *
 * 2. Skip slow swipes and micro-adjustments by checking against
 * a minimum wheel dY value (THRESHOLD_DELTA_Y).
 *
 * 3. Check that the wheel value falls back toward a neutral 0
 * which signifies the end of the inertia / kinetic movement.
 */
export const useWheelDirection = () => {
  const [isInert, setIsInert] = useState<boolean>(true)
  const [direction, setDirection] = useState<DirectionType>()

  const [prevDelta, setPrevDelta] = useState<number>(0)

  const handleWheel = useCallback(
    (e: WheelEvent) => {
      // Cache the absolute/modulus value of the wheel dY
      const modDeltaY = Math.abs(e.deltaY)

      // Cache the previous dY in the next render cycle
      setPrevDelta(e.deltaY)

      if (!prevDelta) {
        return
      }

      e.deltaY < 0 ? setDirection("up") : setDirection("down")

      // Check if scrolling the same direction as last event
      if (Math.sign(prevDelta) === Math.sign(e.deltaY)) {
        directionCounter += 1
      } else {
        directionCounter = 0
      }

      // Prevent random direction jitters
      if (directionCounter < THRESHOLD_DIRECTION) {
        return
      }

      // Block any possible erratic "scroll" events if under the threshold
      if (isInert && modDeltaY < THRESHOLD_DELTA_Y) {
        return
      }

      // If all other checks have passed, flag the wheel as active
      if (isInert) {
        setIsInert(false)

        return
      }

      // Make sure we cover erratic values where the delta first dips, then spikes up
      if (modDeltaY > Math.abs(prevDelta) || modDeltaY > Math.abs(deltaPeak)) {
        deltaPeak = e.deltaY
      }
      // The acceleration has likely finished and now the value tends to 0
      else {
        // If the new dY falls under the threshold, it's safe to reset everything
        if (modDeltaY < THRESHOLD_DELTA_Y) {
          directionCounter = 0
          deltaPeak = 0

          setIsInert(true)
          setPrevDelta(0)
        }
      }
    },
    [prevDelta]
  )

  useEffect(() => {
    window.addEventListener("wheel", handleWheel)

    return () => {
      window.removeEventListener("wheel", handleWheel)
    }
  }, [handleWheel])

  return { isInert, direction }
}

export const useTouchDirection = () => {
  let startY: number

  const [isInert, setIsInert] = useState<boolean>(true)
  const [direction, setDirection] = useState<DirectionType>()

  const handleTouchStart = useCallback((e: TouchEvent) => {
    startY = e.changedTouches[0].screenY

    setIsInert(true)
  }, [])

  const handleTouchEnd = useCallback((e: TouchEvent) => {
    const endY = e.changedTouches[0].screenY
    const dY = endY - startY

    // Ignore any micro movements and potential taps
    if (Math.abs(dY) < THRESHOLD_DELTA_Y) {
      return
    }

    dY > 0 ? setDirection("up") : setDirection("down")
    setIsInert(false)
  }, [])

  useEffect(() => {
    window.addEventListener("touchstart", (e: any) => handleTouchStart(e))
    window.addEventListener("touchend", (e: any) => handleTouchEnd(e))

    // return () => {
    // TODO: How to remove listeners? Check Typescript error
    // }
  }, [handleTouchStart, handleTouchEnd])

  return { isInert, direction }
}
