import React, { useState, useRef, useEffect, useCallback } from "react"
import styled from "styled-components"

// Hooks
import { useDragAndScroll } from "../utils/hooks"

// Components
import { Col, Row } from "./layout/Grid"
import CaseCard from "./CaseCard"
import AnimatedTextScrollWrapper from "./animation/AnimatedTextScrollWrapper"
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"
import Link from "./generic/Link"

// Styles
import textStyles from "../styles/textStyles"
import colors from "../styles/colors"
import { mq } from "../styles/utils/mq"

// Types
import { ICaseCards as IProps } from "../types/prismic/Homepage/CaseCards"

const Cases = ({ title, text, linkLabel, items }: IProps) => {
  const [listenersExist, setListenersExist] = useState<boolean>(false)
  const [activeCard, setActiveCard] = useState<string>("")

  const cases = useRef<HTMLDivElement>(null)

  const [isClickable, cleanUp] = useDragAndScroll(
    cases,
    listenersExist,
    setListenersExist
  )

  useEffect(() => cleanUp(), [])

  return (
    <Container>
      <TextRow>
        <Col m={14}>
          <TitleWrapper>
            <AnimatedTextScrollWrapper
              text={[title]}
              textStyles={textStyles.headingM}
            />
          </TitleWrapper>
        </Col>
        {text && (
          <Col xxs={8} m={8} mOffset={2} l={14} lOffset={2} xxlOffset={2}>
            <AnimatedScrollWrapper>
              <Text
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            </AnimatedScrollWrapper>
          </Col>
        )}
      </TextRow>
      <AnimatedScrollWrapper>
        <CasesRow ref={cases} grabbing={!isClickable}>
          {items.map(caseItem => {
            const key: string = `case-card-${caseItem.client}`
            const handleMouseEnter = useCallback(() => {
              setActiveCard(key)
            }, [])
            const handleMouseLeave = useCallback(() => {
              setActiveCard("")
            }, [])
            return (
              <CaseWrapper key={key}>
                <CaseCard
                  {...caseItem}
                  isActive={activeCard === "" || activeCard === key}
                  isClickable={isClickable}
                  hoverHandlers={{
                    mouseEnter: handleMouseEnter,
                    mouseLeave: handleMouseLeave,
                  }}
                />
              </CaseWrapper>
            )
          })}
        </CasesRow>

        <Row>
          <Col xxs={6} xxsOffset={1} m={11} mOffset={4} l={8} lOffset={7}>
            <Link url="/cases" title={linkLabel} color={colors.white} />
          </Col>
        </Row>
      </AnimatedScrollWrapper>
    </Container>
  )
}

const Container = styled.div`
  margin-left: 8px;

  ${mq.from.L`
  margin-left: 16px; 
`}
`
const TextRow = styled(Row)`
  color: ${colors.white};
  width: 100%;
  margin: 0px 16px 0px 0px;
`

const TitleWrapper = styled.div`
  margin-bottom: 8px;
`

const Text = styled.div`
  p {
    /* duplicate style because of IE */
    margin-bottom: 2.5vw;
    margin-bottom: calc(var(--base-column-size) / 5);

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${mq.from.M`
    p {
      /* duplicate style because of IE */
      margin-bottom: 2vw;
      margin-bottom: calc(var(--base-column-size) / 3);
    }
  `}

  ${mq.from.XL`
    p {
      /* duplicate style because of IE */
      margin-bottom: 1.5vw;
      margin-bottom: calc(var(--base-column-size) / 4);
    }
  `}
`

const CaseWrapper = styled.div`
  flex: 0 0 240px;

  ${mq.from.L`
    flex-basis: 320px;
  `}

  ${mq.from.XL`
    flex-basis: 400px;
  `}

  ${mq.from.XXL`
    flex-basis: 600px;
  `}
`

const CasesRow = styled(Row)<{ grabbing: boolean }>`
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 32px;
  cursor: ${({ grabbing }) => (grabbing ? "grabbing" : "grab")};

  /* hide horizontal scrollbar */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  ${mq.from.L`
    /* duplicate style because of IE */
    margin-bottom: 3.90vw;
    margin-bottom: calc(var(--base-column-size) * 0.625);
  `}

  ${mq.from.XXL`
    /* duplicate style because of IE */
    margin-bottom: 2.5vw;
    margin-bottom: calc(var(--base-column-size) * 0.4);
  `}
`

export default Cases
