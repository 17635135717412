import React from "react"
import { graphql } from "gatsby"

// Types
import { ISliceType } from "../types/prismic/Homepage/"

// Utils
import { formatBlogPost } from "../utils/helpers/prismicHelpers"

// Components
import HomePage from "../pageViews/homePageView"

// Utils
const formatHomepagePrismicData = (slice: any) => {
  switch (slice.type as ISliceType) {
    case "briefings":
      const formattedBriefingItems = slice.items.map((item: any) => {
        return {
          ...item,
          link: {
            url: `/briefing/${item.link.slug}`,
          },
        }
      })

      return {
        type: slice.type,
        id: slice.id,
        data: {
          ...slice.primary,
          items: formattedBriefingItems,
        },
      }
    case "blog":
      const postLimit = slice.primary.postLimit || 5
      const blogPosts = slice.posts.slice(0, postLimit)

      const formattedPosts = blogPosts.map((post: any) => formatBlogPost(post))

      return {
        type: slice.type,
        id: slice.id,
        data: {
          ...slice.primary,
          posts: formattedPosts,
        },
      }
    case "how_we_work":
      const formattedItems = (slice.items || []).map((item: any) => {
        return {
          title: item.offering_title,
          description: item.offering_description,
          image: item.offering_image,
        }
      })
      return {
        type: slice.type,
        id: slice.id,
        data: {
          title: slice.primary.section_title.raw[0].text,
          text: {
            html: slice.primary.section_text.html,
            text: slice.primary.section_text.text,
          },
          media: slice.primary.media && slice.primary.media.url,
          link: {
            label: slice.primary.link_label,
            url: slice.primary.link_path,
          },
          items: formattedItems,
        },
      }
    case "testimonials__video":
      const formattedTestimonialItems = slice.items.map((item: any) => {
        return {
          name: item.name,
          quote: item.quote,
          poster: {
            url: item.poster.url,
            alt: item.poster.alt,
          },
          media: item.media.url,
          logo: {
            url: item.logo.url,
            alt: item.logo.alt,
          },
        }
      })

      return {
        type: slice.type,
        id: slice.id,
        data: {
          title: slice.primary.section_title.raw[0].text,
          text: {
            html: slice.primary.section_text.html,
          },
          items: formattedTestimonialItems,
        },
      }
    case "try_us":
      return {
        type: slice.type,
        id: slice.id,
        data: {
          title: slice.primary.section_title.text,
          linkLabel: slice.primary.link_label,
        },
      }
    case "case_cards":
      const formattedCaseCards = slice.items.map((item: any) => {
        return {
          client: item.client,
          industry: item.industry,
          text: item.text.html,
          image: {
            url: item.image.localFile.childImageSharp.fluid,
            alt: item.image.alt,
          },
          link: item.case.uid,
        }
      })
      return {
        type: slice.type,
        id: slice.id,
        data: {
          title: slice.primary.section_title.text,
          text: slice.primary.section_text.html,
          linkLabel: slice.primary.link_label,
          items: formattedCaseCards,
        },
      }
    default:
      return null
  }
}

const HomePageContainer = ({ data }: any) => {
  if (!data.homePage) {
    return null
  }

  const homepageRawData = data.homePage.data

  const formattedData = {
    metaTitle: homepageRawData.metaTitle,
    metaDescription: homepageRawData.metaDescription,
    heroTitle: homepageRawData.hero_title.raw.map((line: any) => line.text),
    heroText: homepageRawData.hero_text,
    heroMedia: homepageRawData.hero_media,
    heroMediaHeadline: homepageRawData.hero_media_headline,
    heroMediaCopy: homepageRawData.hero_media_copy,
    heroMediaLink: homepageRawData.hero_media_link,
    heroMediaPattern: homepageRawData.hero_media_pattern,
    heroLogoLeft: homepageRawData.hero_logo,
    heroLogoRight: homepageRawData.hero_logo_right,
  }

  const slices = homepageRawData.slices
    .filter((slice: any) => slice.type && slice)
    .map((slice: any) => {
      // Inject additional data where necessary
      if (slice.type === "blog") {
        return {
          ...slice,
          posts: data.blogPosts.nodes,
        }
      }

      return slice
    })
    .map((slice: any) => formatHomepagePrismicData(slice))

  return <HomePage data={formattedData} slices={slices} />
}

export const query = graphql`
  query GetHomePage {
    homePage: prismicHome {
      data {
        metaTitle: meta_title
        metaDescription: meta_description
        hero_logo {
          url
        }
        hero_logo_right {
          url
        }
        hero_text {
          html
        }
        hero_title {
          raw {
            text
          }
        }
        hero_media {
          kind
          url
        }
        slices: body {
          # ... on PrismicHomeBodyBriefings {
          #   type: slice_type
          #   id
          #   primary {
          #     text: section_text {
          #       html
          #     }
          #     title: section_title {
          #       text
          #     }
          #   }
          #   items {
          #     name
          #     link {
          #       slug
          #     }
          #   }
          # }
          # ... on PrismicHomeBodyBlog {
          #   type: slice_type
          #   id
          #   primary {
          #     postLimit: post_limit
          #   }
          # }
          ... on PrismicHomeBodyHowWeWork {
            type: slice_type
            id
            primary {
              section_title {
                raw {
                  text
                }
              }
              section_text {
                html
                text
              }
              link_label
              link_path
            }
            items {
              offering_title
              offering_description
              offering_image {
                kind
                link_type
                url
                alt: name
              }
            }
          }
          ... on PrismicHomeBodyTestimonialsVideo {
            type: slice_type
            id
            primary {
              section_title {
                raw {
                  text
                }
              }
              section_text {
                html
              }
            }
            items {
              quote
              name
              logo {
                alt
                url
              }
              poster {
                alt
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              media {
                url: target
              }
            }
          }
          ... on PrismicHomeBodyTryUs {
            type: slice_type
            id
            primary {
              section_title {
                text
              }
              link_label
            }
          }
          ... on PrismicHomeBodyCaseCards {
            type: slice_type
            id
            primary {
              section_title {
                text
              }
              section_text {
                html
              }
              link_label
            }
            items {
              case {
                uid
              }
              client
              industry
              text {
                html
              }
              image {
                alt
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    # blogPosts: allPrismicBlogPost(
    #   sort: { fields: data___publication_date, order: DESC }
    # ) {
    #   nodes {
    #     slug: uid
    #     data {
    #       title {
    #         text
    #       }
    #       post_type
    #       publication_date(formatString: "DD/MM/YYYY")
    #     }
    #   }
    # }
  }
`

export default HomePageContainer
