import React, { useContext, useEffect } from "react"
import { Link as LinkComponent } from "gatsby"
import styled from "styled-components"

// Types
import { ISlice, IBrief } from "../types/prismic/Homepage"
import { IProps as IHeroProps } from "../components/HomeHero"

// Styling
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Utils
import PageContext from "../utils/context/PageContext"

// Components
import { Row, Col } from "../components/layout/Grid"
import SEO from "../components/layout/SEO"
import HomeHero from "../components/HomeHero"
import HowWeWork from "../components/HowWeWork"
import Container from "../components/layout/Container"
import Link from "../components/generic/Link"
import AnimatedScrollWrapper from "../components/animation/AnimatedScrollWrapper"
import BlogPostList from "../components/BlogPostList"
import Testimonials from "../components/Testimonials"
import TryUs from "../components/TryUs"
import Cases from "../components/Cases"

// Config
import { config as defaultPageTransitionsConfig } from "../components/pageTransitions/config"

interface IData extends IHeroProps {
  metaTitle?: string
  metaDescription?: string
}

interface IProps {
  data: IData
  slices: ISlice[]
}

// Setup
const pageTransitionsConfig = {
  ...defaultPageTransitionsConfig,
  particle: {
    ...defaultPageTransitionsConfig.particle,
    fillStyle: colors.accent1,
  },
}

// Utils
const renderSlice = (slice: ISlice) => {
  switch (slice.type) {
    case "case_cards":
      return <Cases {...slice.data} />
    case "how_we_work":
      return <HowWeWork {...slice.data} />
    case "testimonials__video":
      return <Testimonials {...slice.data} />
    case "try_us":
      return <TryUs {...slice.data} />
    case "briefings":
      return (
        <Container>
          <AnimatedScrollWrapper>
            <Row>
              <Col m={14} mOffset={1}>
                <BriefingList>
                  {slice.data.items &&
                    slice.data.items.length &&
                    slice.data.items.map((item: IBrief, index: number) => (
                      <BriefingItem key={index}>
                        {item.link && item.link.url && (
                          <BriefingLink
                            to={item.link.url}
                            aria-label={`Go to briefing: ${item.name}`}
                          >
                            {item.name}
                          </BriefingLink>
                        )}
                      </BriefingItem>
                    ))}
                </BriefingList>
              </Col>
            </Row>

            <Row>
              <Col xxs={6} xxsOffset={1} m={7} mOffset={8}>
                <Link
                  url="/briefings"
                  title="All briefings"
                  color={colors.white}
                />
              </Col>
            </Row>
          </AnimatedScrollWrapper>
        </Container>
      )
    case "blog":
      return (
        <Container>
          <AnimatedScrollWrapper>
            <Row>
              <Col m={14} mOffset={1}>
                <BlogPostList currentPage="blog" posts={slice.data.posts} />
              </Col>
            </Row>

            <Row>
              <Col xxs={6} xxsOffset={1} m={7} mOffset={8}>
                <Link
                  url="/blog"
                  title="All articles &amp; news"
                  color={colors.white}
                />
              </Col>
            </Row>
          </AnimatedScrollWrapper>
        </Container>
      )
    default:
      return null
  }
}

const HomePage = React.memo(({ data, slices }: IProps) => {
  const page = useContext(PageContext)

  useEffect(() => {
    page.updateColor(colors.black)
    page.updateTransition(pageTransitionsConfig)
  }, [])

  return (
    <>
      <SEO title={data.metaTitle} description={data.metaDescription} />

      <HomeHero {...data} />

      {slices &&
        slices.length > 0 &&
        slices.map((slice: ISlice) => (
          <Section key={slice.id}>{renderSlice(slice)}</Section>
        ))}
    </>
  )
})

const Section = styled.section`
  /* duplicate style because of IE */
  // margin-bottom: 12.5vw;
  // margin-bottom: calc(2 * var(--base-column-size));

  :last-of-type {
    margin-bottom: 0;
  }
`

// Briefings
const BriefingList = styled.ul`
  list-style: none;
  margin: 0;
  margin-bottom: var(--base-column-size);
`

const BriefingItem = styled.li`
  padding-top: 0.05rem;

  &:not(:last-child) {
    /* duplicate style because of IE */
    margin-bottom: 5vh;
    margin-bottom: calc(0.5 * var(--base-column-size));

    ${mq.from.M`
      margin-bottom: var(--base-column-size);
    `}
  }
`

const BriefingLink = styled(LinkComponent)`
  ${textStyles.headingXL};
  display: inline-block;
  color: ${colors.white};
  text-decoration: none;
  line-height: 1.15;

  &:hover {
    color: ${colors.accent1};
  }
`

export default HomePage
