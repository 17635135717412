import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

// Styling
import colors from "../styles/colors"
import { fontWeights } from "../styles/fonts"
import { mq } from "../styles/utils/mq"
import { duration, ease } from "../styles/animations"

// Components
import { Row } from "./layout/Grid"

// Types
import { ICaseCard } from "../types/prismic/Homepage/CaseCards"

interface IProps extends ICaseCard {
  isActive: boolean
  isClickable: boolean
  hoverHandlers: {
    mouseEnter: () => void
    mouseLeave: () => void
  }
}

const CaseCard = ({
  client,
  text,
  image,
  link,
  isActive,
  isClickable,
  hoverHandlers,
}: IProps) => (
  <Container
    isActive={isActive}
    isClickable={isClickable}
    onMouseEnter={hoverHandlers.mouseEnter}
    onMouseLeave={hoverHandlers.mouseLeave}
    onDragStart={(e: React.DragEvent<HTMLDivElement>) => e.preventDefault()}
  >
    <StyledLink to={`/case/${link}`}>
      <Row>
        <StyledBackgroundImage
          fluid={image.url}
          role="img"
          aria-label={image.alt || client}
        />
      </Row>
      <TextRow>
        <TextWrapper>
          <IndustryNameWrapper></IndustryNameWrapper>
          <Title>{client}</Title>
          <QuoteWrapper
            dangerouslySetInnerHTML={{ __html: `— ${text}` }}
          ></QuoteWrapper>
        </TextWrapper>
      </TextRow>
    </StyledLink>
  </Container>
)

const Container = styled.div<{ isActive: boolean; isClickable: boolean }>`
  opacity: ${({ isActive }) => (isActive ? "1" : "0.6")};
  ${({ isClickable }) => !isClickable && "pointer-events: none;"}
  transition: opacity ${duration.fast}ms ${ease.cubic};
  margin-right: 2px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledBackgroundImage = styled(BackgroundImage)`
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-top: 100%;
`

const TextRow = styled(Row)`
  /* duplicate style because of IE */
  margin-top: -6.25vw;
  margin-top: calc(var(--base-column-size) / -2);
  // padding: 0 2.5vw;
  padding-right: calc(var(--base-column-size) / 5);
  // padding-left: calc(var(--base-column-size) / 5);

  ${mq.from.M`
    /* duplicate style because of IE */
    margin-top: -2.25vw;
    margin-top: calc(var(--base-column-size) * -0.36);
  `}

  ${mq.from.L`
    /* duplicate style because of IE */
    margin-top: -1.5vw;
    margin-top: calc(var(--base-column-size) * -0.24);
  `}
`

const TextWrapper = styled.div`
  /* duplicate style because of IE */
  padding-top: 0;
  padding-left: 0px;
  position: relative;

  ${mq.from.XS`
    padding-top:0;
    `}

  ${mq.from.S`
      padding-top:27px;
    `}

    ${mq.from.M`
      padding-top:0;
    `}

    ${mq.from.L`
      padding-top:5px;
    `}

    ${mq.from.XL`
      padding-top:6px;
    `}

    ${mq.from.XXL`
      padding-top:19px;
    `}
`
const IndustryNameWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: ${colors.white};
`

const Title = styled.p`
  margin-bottom: 2px;
  color: ${colors.white};
  font-weight: ${fontWeights.normal};
`

const QuoteWrapper = styled.div`
  color: ${colors.white};
  display: flex;

  > p:last-child {
    margin-bottom: 0;
    margin-left: 2px;
  }
`

export default CaseCard
