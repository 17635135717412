import React, { useState, Dispatch } from "react"

type useDragAndScrollType = (
  ref: React.RefObject<HTMLElement>,
  listenersExist: boolean,
  setListenersExist: Dispatch<React.SetStateAction<boolean>>
) => [boolean, () => void]

const useDragAndScroll: useDragAndScrollType = (
  containerRef,
  listenersExist,
  setListenersExist
) => {
  const [isClickable, setIsClickable] = useState(true)

  const slider = containerRef.current

  let isClickableRef: boolean = true
  let isDown: boolean = false
  let startX: number
  let scrollLeft: number
  let mouseCoordinates = [] as number[]

  const handleMouseDown = (e: MouseEvent) => {
    e.preventDefault()
    if (slider) {
      isDown = true
      startX = e.pageX - slider.offsetLeft
      scrollLeft = slider.scrollLeft
    }
  }

  const handleMouseLeave = () => {
    isDown = false
    if (!isClickableRef) {
      setIsClickable(true)
      isClickableRef = true
    }
  }

  const handleMouseUp = () => {
    isDown = false
    if (!isClickableRef) {
      setIsClickable(true)
      isClickableRef = true
    }
  }

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDown) {
      return
    }

    if (isClickableRef) {
      mouseCoordinates.push(e.pageX)
      if (mouseCoordinates.length > 10) {
        setIsClickable(false)
        isClickableRef = false
        mouseCoordinates = []
      }
    }

    if (slider) {
      const x = e.pageX - slider.offsetLeft
      const walk = x - startX
      slider.scrollLeft = scrollLeft - walk
    }
  }

  if (slider && !listenersExist) {
    setListenersExist(true)
    slider.addEventListener("mousedown", handleMouseDown)
    slider.addEventListener("mouseleave", handleMouseLeave)
    slider.addEventListener("mouseup", handleMouseUp)
    slider.addEventListener("mousemove", handleMouseMove)
  }

  const cleanUp = () => {
    if (slider) {
      setListenersExist(false)
      slider.removeEventListener("mousedown", handleMouseDown)
      slider.removeEventListener("mouseleave", handleMouseLeave)
      slider.removeEventListener("mouseup", handleMouseUp)
      slider.removeEventListener("mousemove", handleMouseMove)
    }
  }

  return [isClickable, cleanUp]
}

export default useDragAndScroll
