import React from "react"
import styled from "styled-components"

// Styling
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Components
import { Row, Col } from "../components/layout/Grid"
import AnimatedScrollWrapper from "../components/animation/AnimatedScrollWrapper"
import AnimatedTextScrollWrapper from "../components/animation/AnimatedTextScrollWrapper"
import { Link as LinkComponent } from "gatsby"

// Types
export interface IProps {
  heroTitle: string[]
  heroText: {
    html: string
  }
  heroMedia: {
    kind: "image" | "document"
    url: string
  }
  heroLogoLeft: {
    url: string
  }
  heroLogoRight: {
    url: string
  }
}

const HomeHero = ({
  heroTitle,
  heroText,
  heroMedia,
  heroLogoLeft,
  heroLogoRight,
}: IProps) => (
  <Container>
    <Row>
      <HeroMediaWrapper l={11}>
        <AnimatedScrollWrapper>
          <HeroMedia>
            {heroMedia.kind === "image" ? (
              <HeroMediaImage background={heroMedia.url} />
            ) : (
              <HeroMediaVideo
                src={heroMedia.url}
                autoPlay
                loop
                muted
                playsInline
              >
                <p>
                  If you are reading this, it is because your browser does not
                  support the HTML5 video element.
                </p>
              </HeroMediaVideo>
            )}
          </HeroMedia>
        </AnimatedScrollWrapper>
      </HeroMediaWrapper>
    </Row>

    <HeroTextRow>
      <Col xxs={8} l={11}>
        {heroTitle && (
          <HeroTitleWrapper>
            <AnimatedTextScrollWrapper
              text={heroTitle}
              textStyles={textStyles.headingL}
            />
          </HeroTitleWrapper>
        )}
      </Col>

      <Col xxs={5} xxsOffset={2} mOffset={8} m={7} l={3.5} lOffset={11.5}>
        {heroText && heroText.html && (
          <AnimatedScrollWrapper>
            <TextContent>
              <HeroText
                dangerouslySetInnerHTML={{
                  __html: heroText.html,
                }}
              />
              <ContactButton to="/contact-us">Contact us</ContactButton>
              <Images>
                <HeroLogo src={heroLogoLeft.url} />
                <HeroLogo src={heroLogoRight.url} />
              </Images>
            </TextContent>
          </AnimatedScrollWrapper>
        )}
      </Col>
    </HeroTextRow>
  </Container>
)

// Main styles
const Container = styled.div`
  color: ${colors.white};
  width: 100%;
  /* duplicate style because of IE */
  margin-bottom: 16.625vw;
  margin-bottom: calc(1.33 * var(--base-column-size));

  /* to make padding top from layout disappear */
  margin-top: -12.5vw;

  ${mq.from.M`
    margin-top: -6.25vw;
  `}

  ${mq.from.L`
    position: relative;
    /* duplicate style because of IE */
    margin-bottom: 12.5vw;
    margin-bottom: calc(2.5 * var(--base-column-size));
  `}

  margin-top: calc(-1 * var(--base-column-size));
`

const HeroMediaWrapper = styled(Col)`
  position: relative;
  overflow: hidden;
`

const HeroMedia = styled.div`
  line-height: 0;
  max-height: 480px;

  &::before {
    display: block;
    width: 100%;
    padding-top: 100%;
    content: "";
  }

  ${mq.from.px(480)`
    &::before {
      padding-top: 480px;
    }
  `}

  ${mq.from.M`
    max-height: initial;

    &::before {
      padding-top: 66.66%;
    }
  `}

  ${mq.from.L`
    &::before {
      padding-top: 54.55%;
    }
  `}
`

export const HeroMediaImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(${({ background }: { background: string }) =>
    background});
  background-size: cover;
  background-position: center center;
`

export const HeroMediaVideo = styled.video`
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);

  ${mq.from.L`
    height: auto;
    width: 100%;
  `}
`

const HeroTextRow = styled(Row)`
  width: 100%;
  padding: 0 8px;

  ${mq.from.XXS`
  padding: 0px 8px;
  
  `}
  ${mq.from.M`
    padding: 0 16px;
  `}

  ${mq.from.L`
  flex-wrap: wrap-reverse;
  position: absolute;
  /* duplicate style because of IE */
  bottom: -6vw;
  bottom: calc(var(--base-column-size) / -1.1);
`}
`

const ContactButton = styled(LinkComponent)`
  ${mq.from.XXS`
    margin: 20px 0;
  `}
  ${mq.from.XS`
    margin: 0 0 20px 0;
  `}
  ${mq.from.L`
    margin: 0 0 40px 0;
  `}
  display: inline-block;
  text-decoration: none;
  padding: 16px 32px;
  color: ${colors.white};
  background: none;
  border: 1px solid ${colors.white};
  border-radius: 37px;
`

const Images = styled.div`
  display: flex;
  ${mq.from.XXS`
      width:95%;
    `}

  ${mq.from.XS`
     width:85%;
    `}  

    ${mq.from.S`
    width:60%;
    `}  

    ${mq.from.M`
      width:79%;
    `}  

    ${mq.from.L`
      width:100%;
      margin-left: 12px;
    `}
`

const HeroTitleWrapper = styled.div`
  color: ${colors.white};
  /* duplicate style because of IE */
  margin-bottom: 6.25vw;
  margin-bottom: calc(var(--base-column-size) / 2);

  ${mq.from.L`
    margin-bottom: 0;
    text-align: right;
    white-space: nowrap;
  `}
`

export const HeroText = styled.div`
  p {
    /* duplicate style because of IE */
    margin-bottom: 2.5vw;
    margin-bottom: calc(var(--base-column-size) / 5);
    &:last-child {
    }
  }

  ${mq.from.L`
    p:last-child {
      /* duplicate style because of IE */
      margin-bottom: 3vw;
      margin-bottom: calc(var(--base-column-size) / 2);
    }
  `}

  ${mq.from.XL`
    p {
      /* duplicate style because of IE */
      margin-bottom: 1.5vw;
      margin-bottom: calc(var(--base-column-size) / 2);

      &:last-child {
        /* duplicate style because of IE */
        margin-bottom: 1.5vw;
        margin-bottom: calc(var(--base-column-size) / 2);
      }
    }
  `}
`
const TextContent = styled.div`
  ${mq.from.L`
    margin-bottom: 120px;
  `}

  ${mq.from.XXL`
    margin-bottom: 148px;
  `}
`
const HeroLogo = styled.img`
  width: 40%;
  height: 45%;
  margin-left: 12px;
`

export default HomeHero
